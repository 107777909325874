import {useLocation, useOutletContext} from "react-router-dom";
import {useEffect, useState} from "react";
import * as React from "react";
import {usePlacesWidget} from "react-google-autocomplete";
import {RbForm, RbInnerGrid, TextField} from "./RbForm";
import {createRide, updateRide} from "../graphql/mutations";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

function RideForm(props) {
    const defaultValues = {
        toCity: '',
        fromCity: '',
        date: '',
        seatsAvail: '',
        notes: ''
    };

    const ctx = useOutletContext<any|null>();
    const [formValues, setFormValues] = useState(defaultValues)
    const [tempLoc, setTempLoc] = React.useState(null)
    const [locationCheck, setLocationCheck] = React.useState({
        from: false,
        to: false
    });
    const [gqlOperation, setGqlOperation] = React.useState(createRide);
    const [gqlInput, setGqlInput] = React.useState<any>({
        userID: ctx.uCog.username
    });

    let location = useLocation();

    useEffect(() => {
        ctx.setLoadingStatus(true)
    }, [])

    useEffect(() => {
        if (props.editingRide) {
            setGqlOperation(updateRide)
            if (props.oldRideData) {
                ctx.setLoadingStatus(false)
                // const newFormValues = {
                //     'toCity': props.oldRideData['toCity'] || '',
                //     'toLoc': props.oldRideData['toLoc'] || null,
                //     'fromCity': props.oldRideData['fromCity'] || '',
                //     'fromLoc': props.oldRideData['fromLoc'] || null,
                //     'date': props.oldRideData['date'] || '',
                //     'seatsAvail': props.oldRideData['seatsAvail'] || '',
                //     'notes': props.oldRideData['notes'] || '',
                // }
                const newFormValues = props.oldRideData
                console.log(newFormValues)
                setFormValues(newFormValues)
                setGqlInput({
                    id: props.oldRideData['id']
                })
                setLocationCheck({
                    from: true,
                    to: true
                })
            }
        } else {
            // setLoadingOpen(false)
            ctx.setLoadingStatus(false)
        }
    }, [props.editingRide, props.oldRideData])

    useEffect(() => {
        if (tempLoc) {
            newFormValues({
                [tempLoc.direction+'City']: tempLoc.city,
                [tempLoc.direction+'Loc']: {
                    lat: tempLoc.lat,
                    lon: tempLoc.lon
                }
            })
            setLocationCheck({
                ...locationCheck,
                [tempLoc.direction]: true
            })
        }
    }, [tempLoc]);

    const newFormValues = (newValues) => {
        const newFormVals = {
            ...formValues,
            ...newValues,
        }
        setFormValues(newFormVals);
        console.log(newFormVals)
    }

    const widgetOptions = {
        fields: ['geometry.location', 'formatted_address', 'address_components'],
        componentRestrictions: {
            country: 'us'
        }
    }

    const selectedPlace = (place, direction) => {
        console.log(place)
        console.log(formValues)
        const city = place.address_components.find(obj => {
                return obj.types.includes('locality')
            }).long_name + ', ' +
            place.address_components.find(obj => {
                return obj.types.includes('administrative_area_level_1')
            }).short_name
        console.log(city)
        setTempLoc({
            direction: direction,
            city: city,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
        })
    }

    const onChangeLocText = (direction) => {
        setLocationCheck({
            ...locationCheck,
            [direction]: false
        })
    }

    const onSaveErrorCheck = () => {
        if (!ctx.uData.firstName || !ctx.uData.lastName || !ctx.uData.contactFacebook)
            throw {
                errors: [
                    {message: 'Please complete your profile before posting a ride'}
                ]
            };
        // console.log(locationCheck)
        if (!locationCheck.from || !locationCheck.to)
            throw {
                errors: [
                    {message: 'Please select locations from the dropdown'}
                ]
            };
    }

    const widgetFrom = usePlacesWidget({
        apiKey: "AIzaSyDF3T18cPEzWUpO65bUdtzBzTtq1kiwM1I",
        options: widgetOptions,
        onPlaceSelected: (place) => {selectedPlace(place, 'from')}
    })

    const widgetTo = usePlacesWidget({
        apiKey: "AIzaSyDF3T18cPEzWUpO65bUdtzBzTtq1kiwM1I",
        options: widgetOptions,
        onPlaceSelected: (place) => {selectedPlace(place, 'to')}
    })

    return (
        <>
            <RbForm
                gqlOperation={gqlOperation}
                gqlInput={gqlInput}
                onSaveBefore={onSaveErrorCheck}
                onSaveAfter={() => {
                    ctx.setUserData()
                    if (location.pathname === '/add' || location.state['backTo'])
                        ctx.navigate('/rides')
                }}
                formValues={formValues}
                setFormValues={setFormValues}
            >
                {ctx.uData && (!ctx.uData.firstName || !ctx.uData.lastName) && (
                    <RbInnerGrid styles={{ width: '100%' }}>
                        <Alert severity="error">
                            <a href="#" onClick={() => ctx.navigate('/settings')}>Please add your first and last name!</a>
                        </Alert>
                    </RbInnerGrid>
                )}
                {ctx.uData && !ctx.uData.contactFacebook && (
                    <RbInnerGrid styles={{ width: '100%' }}>
                        <Alert severity="error">
                            <a href="#" onClick={() => ctx.navigate('/settings')}>Please add your Facebook username so riders can contact you!</a>
                        </Alert>
                    </RbInnerGrid>
                )}
                <RbInnerGrid container spacing={2} direction="row">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="date"
                            label="Date"
                            name="date"
                            required
                            inputProps={{
                                className: "no-placeholder"
                            }}
                            value={formValues.date}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="number"
                            label="Seats Available"
                            name="seatsAvail"
                            required
                            value={formValues.seatsAvail}
                        />
                    </Grid>
                </RbInnerGrid>
                <RbInnerGrid container spacing={2} direction="row">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="From"
                            name="fromCity"
                            required
                            inputRef={widgetFrom.ref}
                            value={formValues.fromCity}
                            onChange={() => onChangeLocText("from")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="To"
                            name="toCity"
                            required
                            inputRef={widgetTo.ref}
                            value={formValues.toCity}
                            onChange={() => onChangeLocText("to")}
                        />
                    </Grid>
                </RbInnerGrid>
                <RbInnerGrid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            label="Notes"
                            name="notes"
                            placeholder="Estimated departure time, luggage, help with gas, anything else your riders should know"
                            value={formValues.notes}
                        />
                    </Grid>
                </RbInnerGrid>
            </RbForm>
        </>
    );
}

export default RideForm;
