// queries

export const getRideNoUser = /* GraphQL */ `
    query GetRide($id: ID!) {
        getRide(id: $id) {
            id
            userID
            date
            fromCity
            fromLoc {
                lat
                lon
            }
            toCity
            toLoc {
                lat
                lon
            }
            seatsAvail
            notes
        }
    }
`;