import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {Auth} from 'aws-amplify';
import {Link, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';


// @ts-ignore
export default function MenuAppBar(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    let navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function signOut() {
        handleClose()
        navigate("/");
        try {
            await Auth.signOut()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const toRides = () => {
        navigate('/rides')
    }

    const toAddRide = () => {
        navigate('/add')
    }

    const toSettings = () => {
        handleClose()
        navigate('/settings')
    }

    // @ts-ignore
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1,
                        textAlign: "left" }}>
                        <Link style={{
                            textDecoration: 'none',
                            color: "white"
                        }} to="/">BYU Ride Board</Link>
                    </Typography>
                    {!props.uCog && <Button color="inherit" onClick={toRides}>Sign In</Button>}
                    {props.uCog && (
                        <>
                            <IconButton
                                color="inherit"
                                style={{shapeRendering: "crispEdges"}}
                                onClick={toAddRide}
                                // size="large"
                            >
                                <AddIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                style={{shapeRendering: "crispEdges"}}
                                onClick={toRides}
                                // size="large"
                            >
                                <FormatListBulletedIcon />
                            </IconButton>
                            <div>
                                <IconButton
                                    // size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <SettingsIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {props.uData && (<MenuItem disabled={true}>Logged in as: {props.uCog.attributes.email}</MenuItem>)}
                                    <MenuItem onClick={toSettings}>Settings</MenuItem>
                                    <MenuItem onClick={signOut}>Sign out</MenuItem>
                                </Menu>
                            </div>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
