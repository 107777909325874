import * as React from 'react';
import {useEffect} from 'react';
import {useOutletContext} from "react-router-dom";
import {deleteRide} from "../graphql/mutations";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    DataGrid,
    GridActionsCellItem,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import dayjs from "dayjs";
import renderCellExpand from "../Components/RenderCellExpand";
import {gqlAsync} from "../Components/CustomGql";

function MyRides(props) {

    const ctx = useOutletContext<any|null>();

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 100,
            flex: 1,
            type: 'date',
            valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('M/D/YYYY')
        },
        {
            field: 'fromCity',
            headerName: 'From',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'toCity',
            headerName: 'To',
            minWidth: 110,
            flex: 1
        },
        {
            field: 'seatsAvail',
            headerName: 'Seats',
            type: 'string',
            width: 75
        },
        {
            field: 'notes',
            headerName: 'Notes',
            sortable: false,
            minWidth: 250,
            flex: 2,
            renderCell: renderCellExpand
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Delete"
                    onClick={() => ctx.navigate(`/edit/${params.id}`, { state: { backTo: '/rides' } })}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => delRide(params.id)}
                />
            ],
        }
    ];

    useEffect(() => {
        if (ctx.uData) {
            props.setLoadStatus(false)
        } else {
            props.setLoadStatus(true)
        }
    }, [ctx.uData])

    const delRide = (id) => {
        props.setLoadStatus(true)
        gqlAsync(deleteRide, {
            input: {
                id: id
            }
        }).then((d) => {
            ctx.setUserData().then(() => {
                props.setLoadStatus(false)
            })
        })
    }

    return (
        <div style={{
            display: 'flex',
            height: '100%',
            // margin: 2
        }}>
            <DataGrid
                className={ctx.classes.dataGridNoSelect}
                autoHeight
                rows={ctx.uData.rides.items}
                rowHeight={80}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10]}
                disableSelectionOnClick
                sx={{
                    borderRadius: 0,
                }}
            />
        </div>
    );
}

export default MyRides;
