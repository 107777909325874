// import * as React from "react";
import React, {useEffect} from "react";
import MuiTextField from "@mui/material/TextField";
import {usePlacesWidget} from "react-google-autocomplete";
import {green} from "@mui/material/colors";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {API, graphqlOperation} from "aws-amplify";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";

function TextField(props) {
    return (
        <MuiTextField
            type="text"
            variant="outlined"
            sx={{
                display: 'flex',
                ...props.sx2
            }}
            {...props}
        />
    )
}

function DateField(props) {
    const dateClearDefault = (event) =>{
        /*
        defaultValue seems to reset every time on the handleChange call,
        we need to set defaultValue again as it is change.

        setTimeout is required for this to work, it needs a small delay
        otherwise defaultValue gets unset
        */
        const target = event.target;
        setTimeout(()=>{
            // console.log("date before", target.defaultValue)
            target.defaultValue = "";
            // console.log("date after", target.defaultValue)
        }, 100);
    }
    return (
        <TextField
            type="date"
            onChange={dateClearDefault}
            onFocus={dateClearDefault}
            inputProps={{
                className: "no-placeholder"
            }}
            {...props}
        />
    )
}

function GoogleLocationField(props) {
    const [tempLoc, setTempLoc] = React.useState(null)

    useEffect(() => {
        if (tempLoc) {
            updateState(props.formValues, props.setFormValues, {
                [props.direction+'City']: tempLoc.city,
                [props.direction+'Loc']: {
                    lat: tempLoc.lat,
                    lon: tempLoc.lon
                }
            })
            updateState(props.locationCheck, props.setLocationCheck, {
                [props.direction]: true
            })
        }
    }, [tempLoc]);

    const widgetOptions = {
        fields: ['geometry.location', 'formatted_address', 'address_components'],
        componentRestrictions: {
            country: 'us'
        }
    }

    const selectedPlace = (place, direction) => {
        console.log(place)
        const city = place.address_components.find(obj => {
                return obj.types.includes('locality')
            }).long_name + ', ' +
            place.address_components.find(obj => {
                return obj.types.includes('administrative_area_level_1')
            }).short_name
        console.log(city)
        setTempLoc({
            direction: direction,
            city: city,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
        })
        // updateState(props.formValues, props.setFormValues, {
        //     [props.direction+'City']: city,
        //     [props.direction+'Loc']: {
        //         lat: place.geometry.location.lat(),
        //         lon: place.geometry.location.lng()
        //     }
        // })
    }

    const onChangeLocText = () => {
        updateState(props.locationCheck, props.setLocationCheck, {
            [props.direction]: false
        })
    }

    const widgetRef = usePlacesWidget({
            apiKey: "AIzaSyDF3T18cPEzWUpO65bUdtzBzTtq1kiwM1I",
            options: widgetOptions,
            onPlaceSelected: (place) => {selectedPlace(place, 'from')}
        })

    const clearField = () => {
        updateState(props.formValues, props.setFormValues, {
            [props.direction+'City']: '',
            [props.direction+'Loc']: {}
        })
        updateState(props.locationCheck, props.setLocationCheck, {
            [props.direction]: false
        })
    }

    const clearButton = () => {
        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={clearField}
                    onMouseDown={clearField}
                    edge="end"
                >
                    {/*{values.showPassword ? <VisibilityOff /> : <Visibility />}*/}
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <TextField
            // label="From"
            // name="fromCity"
            // required
            inputRef={widgetRef.ref}
            // value={formValues.fromCity}
            onChange={onChangeLocText}
            InputProps={props.hasClearButton && {
                endAdornment: clearButton()
            }}
            {...props.tfProps}
        />
    )
}

function RadiusInput(props) {
    const updateRadius = (e, newValue) => {
        // console.log('new value', newValue)
        updateState(props.formValues, props.setFormValues, {
            [props.direction+'Radius']: newValue
        })
    }

    return (
        <Stack spacing={2} direction="row" sx={{ mb: 1, width: "100%" }} alignItems="center">
            <Slider
                valueLabelDisplay="off"
                onChange={updateRadius}
                min={1}
                sx={{
                    flex: 1
                }}
                {...props}
            />
            <Typography>{props.formValues[props.direction+'Radius']} mi</Typography>
        </Stack>
    )
}

function SaveButton(props) {
    const buttonSx = {
        ...(props.statusSuccess && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Box sx={{ m: 1, position: 'relative' }}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                // onClick={props.onClick}
                sx={buttonSx}
                disabled={props.statusLoading}
            >Save</Button>
            {props.statusLoading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    )
}

const updateState = (state, stateSetter, newStates) => {
    const combStates = {
        ...state,
        ...newStates
    }
    stateSetter(combStates)
    // console.log('Set state to', combStates)
}

function RbForm(props) {
    const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
    const [saveButtonSuccess, setSaveButtonSuccess] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState('');

    const handleInputChange = (e) => {
        setSaveButtonSuccess(false)
        const { name, value } = e.target;
        props.setFormValues({
            ...props.formValues,
            [name]: value,
        });
    };

    const saveForm = async (e) => {
        // console.log(formValues)
        e.preventDefault()
        setSaveButtonSuccess(false);
        setSaveButtonLoading(true);
        try {
            props.onSaveBefore?.()
            if (props.gqlOperation) {
                console.log('gqloperation exists')
                const updated = await API.graphql(graphqlOperation(props.gqlOperation, {
                    // input: {
                    //     id: uCog.username,
                    //     ...formValues,
                    //     contactPhone: formValues.contactPhone || null
                    // }
                    input: {
                        ...props.formValues,
                        ...props.gqlInput
                    }
                }))
                console.log(updated)
                props.onSaveAfter?.(updated)
            }
            // setUData(updated['data']['updateUser'])
            setSaveButtonSuccess(true);
            setSaveButtonLoading(false);
        } catch (e) {
            console.log(e)
            setSaveButtonLoading(false)
            setSnackbarMsg('Error: ' + e.errors[0].message)
            setOpenSnackbar(true)
        }
    }

    return (
        <>
            <Box
                component="form"
                display="flex"
                alignItems="center"
                // noValidate
                onChange={handleInputChange}
                onSubmit={saveForm}
                style={props.styleBox}
            >
                <RbOuterGrid container spacing={2} direction="column" sx={{
                    padding: '1rem',
                    alignItems: 'center'
                }}>
                    {props.children}
                    <RbInnerGrid item>
                        <SaveButton
                            // onClick={saveForm}
                            statusLoading={saveButtonLoading}
                            statusSuccess={saveButtonSuccess}
                        />
                    </RbInnerGrid>
                </RbOuterGrid>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMsg}
            />
        </>
    )
}

function RbOuterGrid(props) {
    return (
        <Grid
            container
            spacing={2}
            direction="column"
            sx={{
                padding: '1rem',
                alignItems: 'center'
            }}
        >
            {props.children}
        </Grid>
    )
}

function RbInnerGrid(props) {
    return (
        <Grid
            item
            xs={12}
            style={{
                maxWidth: '700px',
                ...props.styles
            }}
            {...props}
        >
            {props.children}
        </Grid>
    )
}

export {
    TextField,
    DateField,
    GoogleLocationField,
    RadiusInput,
    SaveButton,
    RbInnerGrid,
    RbOuterGrid,
    RbForm,
    updateState
}
