import Button from '@mui/material/Button';
import * as React from 'react';
import {useOutletContext} from "react-router-dom";
import Box from "@mui/material/Box";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {rbAuthOptions} from "../Components/AuthOptions";


function Home() {
    // const [uCog, uData, setUData] = useOutletContext<any|null>();
    const ctx = useOutletContext<any|null>();

    return (
        <div style={{
            margin: "0 1em 1em 1em"
        }}>
            <h1>Welcome to the BYU Ride Board!</h1>
            {ctx.uCog && (
                <>
                    <p>You are signed in as {ctx.uCog["attributes"]["email"]}</p>
                    <Button variant="contained" onClick={() => ctx.navigate('/rides')}>View Rides</Button>
                </>
            )}
            {!ctx.uCog && (
                <>
                    <p>You are not signed in. Sign in or create an account using your school email address.</p>
                    <Button variant="contained" onClick={() => ctx.navigate('/rides')}>Sign In / Create Account</Button>
                </>
            )}

            <Box sx={{
                textAlign: "left",
                margin: "auto",
                maxWidth: "700px"
            }}>
                {/*<p>Thanks for checking this out! This is my first time building an app like this so please let me know if you run into any nasty bugs.</p>*/}
                <p>You are responsible for your safety. Only students at BYU, BYU-Idaho, UVU, and Ensign College are allowed to sign up. Before contacting somebody for a ride, please check out their Facebook profile to verify they are not a scammer.</p>
                <p>Building and running this isn't free, so feel free to <a href="https://account.venmo.com/u/jbschooley">venmo me</a> if it helped you get where you needed to go! :)</p>
                <p>Please <a href="https://www.facebook.com/jbschooley/">contact me</a> if you run into issues or if you are attending a different university and would like access.</p>
                <p style={{
                    textAlign: "center"
                }}>&copy; 2022 Jacob Schooley</p>
            </Box>
        </div>
    );
}

export default Home;
