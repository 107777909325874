import * as React from 'react';
import {API, Auth, graphqlOperation} from "aws-amplify";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {Alert, Backdrop, Button, CircularProgress, Grid, LinearProgress, Modal, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {updateUser} from "../graphql/mutations";
import {RbInnerGrid, SaveButton, RbOuterGrid, RbForm} from "../Components/RbForm";
import Typography from "@mui/material/Typography";
import RbModal from "../Components/RbModal";
import {rbAuthOptions} from "../Components/AuthOptions";

function Settings() {
    const defaultValues = {
        firstName: '',
        lastName: '',
        contactFacebook: '',
        contactInstagram: '',
        contactPhone: ''
    };

    const ctx = useOutletContext<any|null>();
    const [formValues, setFormValues] = useState(defaultValues)
    const [modalOpen, setModalOpen] = useState(false)
    const [fbUsernameError, setFbUsernameError] = useState({
        error: false,
        errorMessage: ''
    })

    // make sure user data is loaded
    useEffect(() => {
        if (ctx.uData) {
            ctx.setLoadingStatus(false)
            setFormValues({
                'firstName': ctx.uData['firstName'] || '',
                'lastName': ctx.uData['lastName'] || '',
                'contactFacebook': ctx.uData['contactFacebook'] || '',
                'contactInstagram': ctx.uData['contactInstagram'] || '',
                'contactPhone': ctx.uData['contactPhone'] || ''
            })
        } else {
            ctx.setLoadingStatus(true)
        }
    }, [ctx.uData])

    const onSave = (savedData) => {
        ctx.setUData(savedData['data']['updateUser'])
    }

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const handleFbUsernameChange = (e) => {
        console.log(e.target.value)
        if (e.target.value.includes('/')) {
            setFbUsernameError({
                error: true,
                errorMessage: 'Your username should not include slashes (/)'
            })
        } else if (e.target.value.includes(' ')) {
            setFbUsernameError({
                error: true,
                errorMessage: 'Your username should not include spaces'
            })
        } else {
            setFbUsernameError({
                error: false,
                errorMessage: ''
            })
        }
    }

    return (
        <>
            <RbModal
                open={modalOpen}
                onClose={handleClose}
                title="Configure your Facebook account"
            >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <h4>To get your Facebook username:</h4>
                    <ol>
                        <li>
                            Navigate to <a href="https://facebook.com/me" target="_blank" rel="noreferrer">https://facebook.com/me</a>.
                        </li>
                        <li>
                            Copy <strong>just your username</strong> from the URL:
                            <img
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '8px 0',
                                }}
                                src={process.env.PUBLIC_URL + "/assets/facebook_username.jpg"}
                            />
                            <img
                                style={{
                                    maxWidth: '100%',
                                    marginTop: '8px 0',
                                    border: '3px solid black'
                                }}
                                src={process.env.PUBLIC_URL + "/assets/facebook_username2.png"}
                            />
                        </li>
                        <li>
                            Paste it into your Ride Board settings
                        </li>
                    </ol>
                    <h4>To make sure you can receive messages from riders:</h4>
                    <ol>
                        <li>Open the Messenger app and tap on your profile picture.</li>
                        <li>Go to <em>Privacy</em> &#62; <em>Message delivery</em>.</li>
                        <li>Verify that none of the options show <em>Don't receive requests</em>. If any do, change it to <em>Chats</em> or <em>Message requests</em>.</li>
                        <li>Make sure to check your Message Requests often!</li>
                    </ol>
                </Typography>
            </RbModal>
            <div>
                {/*<Backdrop*/}
                {/*    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}*/}
                {/*    open={loadingOpen}*/}
                {/*>*/}
                {/*    <CircularProgress color="inherit" />*/}
                {/*</Backdrop>*/}
                <h1>Settings</h1>
                <RbForm
                    gqlOperation={updateUser}
                    gqlInput={{
                        id: ctx.uCog.username,
                        contactPhone: formValues.contactPhone || null
                    }}
                    onSaveAfter={onSave}
                    formValues={formValues}
                    setFormValues={setFormValues}
                >
                    <RbInnerGrid styles={{ width: '100%' }}>
                        <h3 style={{ margin: 0, textAlign: 'left' }}>Name</h3>
                    </RbInnerGrid>
                    <RbInnerGrid container spacing={2} direction="row">
                        <Grid item xs={6} sm={6}>
                            <TextField
                                type="text"
                                label="First"
                                name="firstName"
                                value={formValues.firstName}
                                variant="outlined"
                                sx={{ display: 'flex' }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                type="text"
                                label="Last"
                                name="lastName"
                                value={formValues.lastName}
                                variant="outlined"
                                // fullWidth
                                sx={{ display: 'flex' }}
                            />
                        </Grid>
                    </RbInnerGrid>
                    <RbInnerGrid styles={{ width: '100%' }}>
                        <h3 style={{ margin: 0, textAlign: 'left' }}>Contact Information</h3>
                    </RbInnerGrid>
                    <RbInnerGrid container spacing={2} direction="row">
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="text"
                                label="Facebook Username"
                                name="contactFacebook"
                                value={formValues.contactFacebook}
                                variant="outlined"
                                sx={{ display: 'flex' }}
                                error={fbUsernameError.error}
                                helperText={
                                    fbUsernameError.error &&
                                    fbUsernameError.errorMessage
                                }
                                onChange={handleFbUsernameChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Alert
                                className={ctx.classes.AlertTextBoxSize}
                                severity="info"
                            >
                                <a href="#" onClick={handleOpen}>Configure your Facebook account</a>
                            </Alert>
                        </Grid>
                        {/*<Grid item xs={12} sm={4}>*/}
                        {/*    <TextField*/}
                        {/*        type="text"*/}
                        {/*        label="Instagram"*/}
                        {/*        name="contactInstagram"*/}
                        {/*        value={formValues.contactInstagram}*/}
                        {/*        variant="outlined"*/}
                        {/*        // fullWidth*/}
                        {/*        sx={{ display: 'flex' }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={4}>*/}
                        {/*    <TextField*/}
                        {/*        type="tel"*/}
                        {/*        label="Phone"*/}
                        {/*        name="contactPhone"*/}
                        {/*        value={formValues.contactPhone}*/}
                        {/*        variant="outlined"*/}
                        {/*        inputProps={{*/}
                        {/*            pattern: "[0-9]{10}"*/}
                        {/*        }}*/}
                        {/*        sx={{ display: 'flex' }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                    </RbInnerGrid>
                </RbForm>
            </div>
        </>
    );
}

export default withAuthenticator(Settings, rbAuthOptions);
