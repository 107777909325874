import * as React from 'react';
import {useTheme, View} from "@aws-amplify/ui-react";
import { AmplifyTheme } from "aws-amplify-react";

const components = {
    // Header() {
    //     const { tokens } = useTheme();
    //
    //     return (
    //         <View textAlign="center" padding={tokens.space.large}>
    //             {/*<img*/}
    //             {/*    alt="Amplify logo"*/}
    //             {/*    src="https://docs.amplify.aws/assets/logo-dark.svg"*/}
    //             {/*/>*/}
    //             <h1>BYU Ride Board</h1>
    //         </View>
    //     );
    // },

    // Footer() {
    //     const { tokens } = useTheme();
    //
    //     return (
    //         <View textAlign="center" padding={tokens.space.large}>
    //             {/*<Text color={`${tokens.colors.neutral['80']}`}>*/}
    //             {/*    &copy; All Rights Reserved*/}
    //             {/*</Text>*/}
    //         </View>
    //     );
    // },
    //
    // SignIn: {
    //     Header() {
    //         const { tokens } = useTheme();
    //
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Sign in to your account
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         const { toResetPassword } = useAuthenticator();
    //
    //         return (
    //             <View textAlign="center">
    //                 <Button
    //                     fontWeight="normal"
    //                     onClick={toResetPassword}
    //                     size="small"
    //                     variation="link"
    //                 >
    //                     Reset Password
    //                 </Button>
    //             </View>
    //         );
    //     },
    // },
    //
    // SignUp: {
    //     Header() {
    //         const { tokens } = useTheme();
    //
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Create a new account
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         const { toSignIn } = useAuthenticator();
    //
    //         return (
    //             <View textAlign="center">
    //                 <Button
    //                     fontWeight="normal"
    //                     onClick={toSignIn}
    //                     size="small"
    //                     variation="link"
    //                 >
    //                     Back to Sign In
    //                 </Button>
    //             </View>
    //         );
    //     },
    // },
    // ConfirmSignUp: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Enter Information:
    //             </Heading>
    //         );
    //     },
    //     // Footer() {
    //     //     return <Text>Footer Information</Text>;
    //     // },
    // },
};

const rbAuthOptions = {
    // variation: "default",
    components: components,
    // theme: {
    //     name: 'my-theme',
    //     tokens: {
    //         colors: {
    //             font: {
    //                 primary: { value: '#008080' },
    //             },
    //         },
    //     },
    // }
    // theme:{
    //     ...AmplifyTheme,
    //     sectionHeader:{
    //         ...AmplifyTheme.sectionHeader,
    //         color:"red",
    //     },
    //     formSection: {
    //         ...AmplifyTheme.formSection,
    //         backgroundColor: "green",
    //     },
    //     sectionFooter: {
    //         ...AmplifyTheme.sectionFooter,
    //         backgroundColor: "purple"
    //     },
    //     button: {
    //         ...AmplifyTheme.button,
    //         backgroundColor: "blue"
    //     }
    // }
}

export {rbAuthOptions};
