import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Settings from "./Routes/Settings"
import Home from "./Routes/Home"
import {Button} from "@mui/material";
import AddRide from "./Routes/AddRide";
import EditRide from "./Routes/EditRide";
import RideBoardScrn from "./Routes/RideBoardScrn";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/rides" element={<RideBoardScrn />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/add" element={<AddRide />} />
                    <Route path="/edit/:rideID" element={<EditRide />} />
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                                <Button href={"/"}>Home</Button>
                            </main>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
