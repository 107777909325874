import * as React from 'react';
import {withAuthenticator} from "@aws-amplify/ui-react";
import RideForm from "../Components/RideForm";
import {rbAuthOptions} from "../Components/AuthOptions";

function AddRide() {
    return (
        <div>
            <h1>Add Ride</h1>
            <RideForm />
        </div>
    );
}

export default withAuthenticator(AddRide, rbAuthOptions);
