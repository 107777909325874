/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchRides2 = /* GraphQL */ `
  query SearchRides2(
    $filter: SearchRidesInput!
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRides2(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userID
        user {
          id
          firstName
          lastName
          schoolID
          contactFacebook
          contactInstagram
          contactPhone
          createdAt
          updatedAt
        }
        date
        fromCity
        fromLoc {
          lat
          lon
        }
        toCity
        toLoc {
          lat
          lon
        }
        seatsAvail
        notes
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      schoolID
      contactFacebook
      contactInstagram
      contactPhone
      rides {
        items {
          id
          userID
          date
          fromCity
          toCity
          seatsAvail
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        schoolID
        contactFacebook
        contactInstagram
        contactPhone
        rides {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRide = /* GraphQL */ `
  query GetRide($id: ID!) {
    getRide(id: $id) {
      id
      userID
      user {
        id
        firstName
        lastName
        schoolID
        contactFacebook
        contactInstagram
        contactPhone
        rides {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      fromCity
      fromLoc {
        lat
        lon
      }
      toCity
      toLoc {
        lat
        lon
      }
      seatsAvail
      notes
      createdAt
      updatedAt
    }
  }
`;
export const listRides = /* GraphQL */ `
  query ListRides(
    $filter: ModelRideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          firstName
          lastName
          schoolID
          contactFacebook
          contactInstagram
          contactPhone
          createdAt
          updatedAt
        }
        date
        fromCity
        fromLoc {
          lat
          lon
        }
        toCity
        toLoc {
          lat
          lon
        }
        seatsAvail
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchRides = /* GraphQL */ `
  query SearchRides(
    $filter: SearchableRideFilterInput
    $sort: [SearchableRideSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRideAggregationInput]
  ) {
    searchRides(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userID
        user {
          id
          firstName
          lastName
          schoolID
          contactFacebook
          contactInstagram
          contactPhone
          createdAt
          updatedAt
        }
        date
        fromCity
        fromLoc {
          lat
          lon
        }
        toCity
        toLoc {
          lat
          lon
        }
        seatsAvail
        notes
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAssetCollection = /* GraphQL */ `
  query GetAssetCollection($id: ID!) {
    getAssetCollection(id: $id) {
      id
      data
      createdAt
      updatedAt
    }
  }
`;
export const listAssetCollections = /* GraphQL */ `
  query ListAssetCollections(
    $filter: ModelAssetCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
