import {useEffect, useState} from "react";
import ReactDOMServer from "react-dom/server";
import dayjs from "dayjs";
import * as React from "react";

function RbMap(props) {
    // console.log('rbmapcomponent triggered')
    const [map, setMap] = useState<google.maps.Map>();
    // const ref = useRef();


    useEffect(() => {
        console.log('ref triggered')
        if (props.gMapRef.current && !map) {
            // const opts = {
            //     center: props.center as google.maps.LatLngLiteral,
            //     zoom: props.zoom as number,
            // }
            setMap(new window.google.maps.Map(props.gMapRef.current, {
                center: props.center as google.maps.LatLngLiteral,
                zoom: props.zoom as number,
            }));
        }
    }, [props.gMapRef, map]);

    const infowindow = new google.maps.InfoWindow({
        maxWidth: 300
    });

    let currentInfo;

    const openInfo = (e, obj, content) => {
        //Close active window if exists - [one might expect this to be default behaviour no?]
        if(currentInfo != null) {
            // console.log('closing old popup')
            currentInfo.close();
        }

        // console.log(e, obj)

        // Open InfoWindow
        // @ts-ignore
        infowindow.setContent(content);
        infowindow.setPosition(e.latLng);
        infowindow.open(map, obj);

        // Store new open InfoWindow in global variable
        // setActivePopup(infowindow)
        currentInfo = infowindow
    }

    useEffect(() => {
        if (props.ridesList && map) {

            console.log('adding ride list to map')
            // console.log(props.gMapRef)
            props.ridesList.items.forEach(e => {
                console.log(e)
                const marker1 = new google.maps.Marker({
                    position: { lat: e.fromLoc.lat, lng: e.fromLoc.lon },
                    map: map,
                    icon: {
                        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                    }
                });
                const marker2 = new google.maps.Marker({
                    position: { lat: e.toLoc.lat, lng: e.toLoc.lon },
                    map: map,
                    icon: {
                        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                    }
                });
                const line = new google.maps.Polyline({
                    path: [
                        { lat: e.fromLoc.lat, lng: e.fromLoc.lon },
                        { lat: e.toLoc.lat, lng: e.toLoc.lon }
                    ],
                    geodesic: false,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    map: map,
                });

                const content = ReactDOMServer.renderToString(
                    <div className="maps-info-window">
                        {dayjs(e.date).format('M/D/YYYY')}<br />
                        <strong>From:</strong> {e.fromCity}<br />
                        <strong>To:</strong> {e.toCity}<br />
                        <strong>Seats:</strong> {e.seatsAvail}<br />
                        {props.schoolTag(e.user.schoolID)} {e.user.firstName} {e.user.lastName} <br />
                        {e.notes}<br />
                        <a href={'https://facebook.com/'+e.user.contactFacebook} target="_blank" rel="noreferrer">facebook</a> <a href={'https://m.me/'+e.user.contactFacebook} target="_blank" rel="noreferrer">messenger</a>
                    </div>
                );
                google.maps.event.addListener(marker1, 'click', (e) => openInfo(e, marker1, content));
                google.maps.event.addListener(marker2, 'click', (e) => openInfo(e, marker2, content));
                google.maps.event.addListener(line, 'click', (e) => openInfo(e, line, content));
            })
        }
    }, [props.ridesList, map])

    return <div ref={props.gMapRef} id="map" />;
}

export default RbMap
