import * as React from 'react';
import './App.css';
import Amplify, {API, Auth, graphqlOperation, Hub} from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { ThemeProvider, Theme, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import MenuAppBar from "./Components/MenuAppBar";
import {useState, useEffect, useRef} from "react";
import {Outlet, Link, useNavigate, useOutletContext} from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {getUser, getAssetCollection} from "./graphql/queries";
import {Backdrop, CircularProgress} from "@mui/material";
import { makeStyles } from '@mui/styles';
import Box from "@mui/material/Box";
import {red} from "@mui/material/colors";
Amplify.configure(awsExports);

// const { ampThemeTokens } = useTheme();
const amplifyTheme: Theme = {
    name: 'my-theme',
    tokens: {
        colors: {
            // font: {
            //     primary: { value: 'red' },
            //     // ...
            // },
            brand: {
                primary: {
                    // '10': tokens.colors.teal['100'],
                    '80': { value: 'rgb(25, 118, 210)' },
                    '90': { value: '#1565c0' },
                    '100': { value: 'rgb(25, 118, 210)' },
                },
            }
        },
    },
};

function App() {
    const [uCog, setUCog] = useState<any | null>(null);
    const [uData, setUData] = useState<any | null>(null);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const useStyles = makeStyles({
        dataGridNoSelect: {
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                outline: 'none',
            },
        },
        AlertTextBoxSize: {
            '&.MuiPaper-root': {
                padding: '10px 16px',
            },
        },
        ModalCloseButton: {
            position: 'absolute',
            float: 'right',
            top: '-4px',
            right: '-4px',
            // left: '50%',
        }
    });
    const classes = useStyles();

    let navigate = useNavigate();

    useEffect(() => {
        if (uCog) {
            setUserData()
        } else {
            setUData(null)
        }
    }, [uCog])

    const setUserData = async () => {
        // setLoadingStatus(true)
        const dataGql = await API.graphql(graphqlOperation(getUser, {
            id: uCog.username
        }))
        setUData(dataGql['data']['getUser'])
        console.log('udata set', dataGql['data']['getUser'])
        // setLoadingStatus(false)
    }

    const clearUserData = () => {
        setUCog(null)
        // setUData(null)
    }

    useEffect(() => {
        const subscribeToHub = async () => {
            Hub.listen("auth", async ({payload: {event, data}}) => {
                switch (event) {
                    case "signIn":
                        setUCog(data);
                        // await setUserData2()
                        // await setUserData(data)
                        break;
                    case "signOut":
                        clearUserData()
                        break;
                    default:
                        break;
                }
            });
        };
        subscribeToHub();
    }, []);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async data => {
                setUCog(data);
            })
            .catch(console.error);
    }, []);

    const ctx = {
        uCog: uCog,
        uData: uData,
        setUData: setUData,
        navigate: navigate,
        setUserData: setUserData,
        setLoadingStatus: setLoadingStatus,
        classes: classes
    }

    // @ts-ignore
    return (
        <ThemeProvider theme={amplifyTheme}>
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }}
                    open={loadingStatus}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="App">
                    <MenuAppBar uCog={uCog} uData={uData} navigate={navigate} />
                    <Outlet context={ctx} />
                </div>
                {/*<Box>*/}
                {/*    <p style={{*/}
                {/*        textAlign: "center"*/}
                {/*    }}>&copy; 2022 Jacob Schooley</p>*/}
                {/*</Box>*/}
            </>
        </ThemeProvider>
    );
}

export default App;
