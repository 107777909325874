import * as React from 'react';
import {API, graphqlOperation} from "aws-amplify";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import RideForm from "../Components/RideForm";
import {getRideNoUser} from "../graphql/custom"
import {rbAuthOptions} from "../Components/AuthOptions";

function EditRide(props) {
    const [oldRideData, setOldRideData] = React.useState(null)

    const { rideID } = useParams();

    useEffect(() => {
        const getOldRideData = async (id) => {
            const dataGql = await API.graphql(graphqlOperation(getRideNoUser, {
                id: id
            }))
            setOldRideData(dataGql['data']['getRide'])
        }
        if (rideID) {
            getOldRideData(rideID)
        }

    }, []);

    return (
        <div>
            <h1>Edit Ride</h1>
            <RideForm
                editingRide={true}
                oldRideData={oldRideData}
            />
        </div>
    );
}

export default withAuthenticator(EditRide, rbAuthOptions);
