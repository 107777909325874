import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import {getAssetCollection, searchRides2} from "../graphql/queries";
import {Button, ButtonGroup} from "@mui/material";
import {InlineIcon} from '@iconify/react';
import IconButton from "@mui/material/IconButton";
import {gqlAsync} from "./CustomGql";
import {DataGrid, GridValueFormatterParams} from "@mui/x-data-grid";
import dayjs from "dayjs";
import renderCellExpand from "./RenderCellExpand";
import Box from '@mui/material/Box';
import RbFilter from "./RbFilter";
import { Wrapper } from '@googlemaps/react-wrapper';
import RbMap from './RbMap';

function RideBoard(props) {
    const defaultFilterValues = {
        fromCity: '',
        fromLoc: {},
        fromRadius: 20,
        toCity: '',
        toLoc: {},
        toRadius: 20,
        dateFrom: '',
        dateTo: '',
        minSeats: 1
    };

    const [ridesList, setRidesList] = useState<any | null>(null);
    const [assetCollection, setAssetCollection] = useState<any | null>(null);
    const [selectedView, setSelectedView] = useState<string>("list");
    const [filterValues, setFilterValues] = useState(defaultFilterValues)
    const ctx = useOutletContext<any|null>();
    const gMapRef = useRef(null);

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 100,
            flex: 1,
            type: 'date',
            valueFormatter: (params: GridValueFormatterParams) => dayjs(params.value as string).format('M/D/YYYY')
        },
        {
            field: 'fromCity',
            headerName: 'From',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'toCity',
            headerName: 'To',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'driver',
            headerName: 'Driver',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: params => {
                // console.log(params)
                return (
                    <div>
                        <div style={{
                            // lineHeight: '22px',
                            // verticalAlign: 'middle'
                        }}>
                            {schoolTag(params.row.user.schoolID)} <span className="driver-tag">{params.row.user.firstName} {params.row.user.lastName}</span>
                        </div>
                    </div>
                )
            }
        },
        {
            field: 'seatsAvail',
            headerName: 'Seats',
            type: 'string',
            width: 75,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            sortable: false,
            minWidth: 250,
            flex: 2,
            renderCell: renderCellExpand
        },
        {
            field: 'actions',
            headerName: 'Message',
            type: 'actions',
            width: 100,
            getActions: (params) => [
                // gridSocialButton('mdi:facebook', 'Facebook', 'https://facebook.com/'+params.row.user.contactFacebook),
                // gridSocialButton('mdi:facebook-messenger', 'Messenger', 'https://m.me/'+params.row.user.contactFacebook)
                socialButton('mdi:facebook', 'https://facebook.com/'+params.row.user.contactFacebook),
                socialButton('mdi:facebook-messenger',  'https://m.me/'+params.row.user.contactFacebook)
            ],
        }
    ];

    // loading status
    useEffect(() => {
        const newLoadStatus = !ridesList || !assetCollection
        props.setLoadStatus(newLoadStatus)
        // console.log('assets', assetCollection)
    }, [ridesList, assetCollection])

    // get rides list on load
    useEffect(() => {
        setRidesList(null)
        if (ctx.uData)
            getRidesList()
    }, [ctx.uData]);

    const getRidesList = async () => {
        // assets
        gqlAsync(getAssetCollection, {
            id: "Schools"
        }).then((d) => {
            const dataJson = JSON.parse(d['data']['getAssetCollection']['data'])
            setAssetCollection(dataJson)
        })
        // rides list
        // gqlAsync(listRides).then((d) => {
        //     setRidesList(d['data']['listRides'])
        // })
        gqlAsync(searchRides2, {
            filter: {
                dateFrom: filterValues.dateFrom || dayjs().format("YYYY-MM-DD"),
                dateTo: filterValues.dateTo || null,
                fromLoc: Object.keys(filterValues.fromLoc).length !== 0 ? filterValues.fromLoc : null,
                fromRadius: filterValues.fromRadius || null,
                toLoc: Object.keys(filterValues.toLoc).length !== 0 ? filterValues.toLoc : null,
                toRadius: filterValues.toRadius || null,
                minSeats: filterValues.minSeats || null
            }
        }).then((d) => {
            console.log('got data', d)
            setRidesList(d['data']['searchRides2'])
        })
    }

    const refreshRidesList = async () => {
        setRidesList(null)
        await getRidesList()
    }

    const schoolTag = (name) => {
        return (
            <span
                className="driver-tag"
                style={{
                    backgroundColor: '#' + assetCollection[name]['colorBg'],
                    color: '#' + assetCollection[name]['colorText']
                }}
            >
                {assetCollection[name]['name']}
            </span>
        )
    }

    const socialButton = (icon, url) => {
        return (
            <IconButton
                onClick={() => openInNewTab(url)}
                size="small"
                // color="inherit"
            >
                <InlineIcon
                    icon={icon}
                    fontSize={24}
                    // style={{ margin: '0 2px'}}
                />
            </IconButton>
        )
    }

    const openInNewTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    }

    // @ts-ignore
    return (
        <>
            <Box
                sx={{
                    margin: "0.5em 0"
                }}
            >
                <ButtonGroup variant="outlined" disableElevation>
                    <Button
                        variant={selectedView === "list" ? "contained" : "outlined"}
                        onClick={() => setSelectedView("list")}
                    >List</Button>
                    <Button
                        variant={selectedView === "map" ? "contained" : "outlined"}
                        onClick={() => setSelectedView("map")}
                    >Map</Button>
                </ButtonGroup>
                <RbFilter
                    refreshList={refreshRidesList}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            </Box>

            <div style={{
                display: 'flex',
                height: '100%',
                // margin: 2
            }}>
                {ridesList && assetCollection && selectedView === "list" && (
                    <DataGrid
                        className={ctx.classes.dataGridNoSelect}
                        autoHeight
                        rows={ridesList.items}
                        // rows={rows}
                        rowHeight={80}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10]}
                        disableSelectionOnClick
                        sx={{
                            borderRadius: 0,
                        }}
                    />
                )}
                {ridesList && assetCollection && selectedView === "map" && (
                    <Wrapper apiKey="AIzaSyDF3T18cPEzWUpO65bUdtzBzTtq1kiwM1I">
                        <RbMap
                            center={{ lat: assetCollection[ctx.uData['schoolID']]['loc']['lat'], lng: assetCollection[ctx.uData['schoolID']]['loc']['lon'] }}
                            zoom={7}
                            ridesList={ridesList}
                            gMapRef={gMapRef}
                            schoolTag={schoolTag}
                        />
                    </Wrapper>
                )}
            </div>
        </>

    );
}

export default RideBoard;
