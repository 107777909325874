import * as React from "react";
import RbModal from "./RbModal";
import {RbForm, RbInnerGrid, DateField, GoogleLocationField, RadiusInput, updateState} from "./RbForm";
import {Button, Grid, InputAdornment, Slider} from "@mui/material";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';


function RbFilter(props) {

    const [modalOpen, setModalOpen] = useState(false)
    const [locationCheck, setLocationCheck] = React.useState({
        from: false,
        to: false
    });

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const clearButton = (onClick) => {
        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={onClick}
                    onMouseDown={onClick}
                    edge="end"
                >
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        )
    }

    const onSaveFilters = async () => {
        console.log('filter values', props.filterValues)
        props.refreshList()
        handleModalClose()
    }

    const seatSliderMarks = [
        {
            value: 0,
            label: 'Any',
        },
        {
            value: 1,
            label: '1+',
        },
        {
            value: 2,
            label: '2+',
        },
        {
            value: 3,
            label: '3+',
        },
        {
            value: 4,
            label: '4+',
        },
        {
            value: 5,
            label: '5+',
        },
    ];

    return (
        <>

            <RbModal
                open={modalOpen}
                onClose={handleModalClose}
                title="Filters"
                styleModal={{
                    maxWidth: '450px'
                }}
            >
                <RbForm
                    gqlOperation={null}
                    formValues={props.filterValues}
                    setFormValues={props.setFilterValues}
                    onSaveBefore={onSaveFilters}
                    styleBox={{
                        width: '100%'
                    }}
                >
                    <RbInnerGrid styles={{ width: '100%' }}>
                        <h3 style={{ margin: 0, textAlign: 'left' }}>Date</h3>
                    </RbInnerGrid>
                    <RbInnerGrid container spacing={2} direction="row">
                        <Grid item xs={12} sm={6} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <DateField
                                label="From"
                                name="dateFrom"
                                value={props.filterValues.dateFrom}
                                style={{
                                    flex: 1
                                }}
                                InputProps={{
                                    endAdornment: clearButton(() => {
                                        updateState(props.filterValues, props.setFilterValues, {
                                            dateFrom: ''
                                        })
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <DateField
                                label="To"
                                name="dateTo"
                                value={props.filterValues.dateTo}
                                style={{
                                    flex: 1
                                }}
                                InputProps={{
                                    endAdornment: clearButton(() => {
                                        updateState(props.filterValues, props.setFilterValues, {
                                            dateTo: ''
                                        })
                                    })
                                }}
                            />
                        </Grid>
                        <RbInnerGrid styles={{ width: '100%' }}>
                            <h3 style={{ margin: 0, textAlign: 'left' }}>Location</h3>
                        </RbInnerGrid>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <GoogleLocationField
                                tfProps={{
                                    "label": "From",
                                    "name": "fromCity",
                                    "value": props.filterValues.fromCity,
                                    "style": {
                                        flex: 1
                                    },
                                }}
                                direction="from"
                                formValues={props.filterValues}
                                setFormValues={props.setFilterValues}
                                locationCheck={locationCheck}
                                setLocationCheck={setLocationCheck}
                                hasClearButton={true}
                            />
                        </Grid>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <RadiusInput
                                aria-label="Origin Radius"
                                value={props.filterValues.fromRadius}
                                formValues={props.filterValues}
                                setFormValues={props.setFilterValues}
                                direction="from"
                            />
                        </Grid>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <GoogleLocationField
                                tfProps={{
                                    "label": "To",
                                    "name": "toCity",
                                    "value": props.filterValues.toCity,
                                    "style": {
                                        flex: 1
                                    },
                                }}
                                direction="to"
                                formValues={props.filterValues}
                                setFormValues={props.setFilterValues}
                                locationCheck={locationCheck}
                                setLocationCheck={setLocationCheck}
                                hasClearButton={true}
                            />
                        </Grid>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <RadiusInput
                                aria-label="Destination Radius"
                                value={props.filterValues.toRadius}
                                formValues={props.filterValues}
                                setFormValues={props.setFilterValues}
                                direction="to"
                            />
                        </Grid>
                        <RbInnerGrid styles={{ width: '100%' }}>
                            <h3 style={{ margin: 0, textAlign: 'left' }}>Seats Available</h3>
                        </RbInnerGrid>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Slider
                                aria-label="Seats Available"
                                value={props.filterValues.minSeats}
                                valueLabelDisplay="off"
                                step={1}
                                min={0}
                                max={5}
                                marks={seatSliderMarks}
                                onChange={(e, nv) => {
                                    const num = nv as number
                                    updateState(props.filterValues, props.setFilterValues, {
                                        minSeats: num
                                    })
                                }}
                                sx={{
                                    flex: 1
                                }}
                            />
                        </Grid>
                    </RbInnerGrid>
                </RbForm>
            </RbModal>
            <Button onClick={handleModalOpen}>Filters</Button>
        </>
    )
}

export default RbFilter;
