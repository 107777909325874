import * as React from 'react';
import {useEffect} from 'react';
import {withAuthenticator} from "@aws-amplify/ui-react";
import {useOutletContext} from "react-router-dom";
import RideBoard from "../Components/RideBoard";
import {rbAuthOptions} from "../Components/AuthOptions";
import MyRides from "../Components/MyRides";

function RideBoardScrn() {
    // const [uCog, uData, setUData, navigate] = useOutletContext<any|null>();
    const ctx = useOutletContext<any|null>();
    const [rbLoadStatus, setRbLoadStatus] = React.useState(false);
    const [myRidesLoadStatus, setMyRidesLoadStatus] = React.useState(false);

    useEffect(() => {
        // console.log('rb', rbLoadStatus, 'my', myRidesLoadStatus)
        ctx.setLoadingStatus(rbLoadStatus || myRidesLoadStatus)
    }, [rbLoadStatus, myRidesLoadStatus])

    useEffect(() => {
        console.log('board screen reset')
    }, [])

    return (
        <>
            {ctx.uData && ctx.uData.rides.items.length>0 && (
                <>
                    <h1>My Rides</h1>
                    <MyRides
                        setLoadStatus={setMyRidesLoadStatus}
                    />
                    <h1>Ride Board</h1>
                </>
            )}
            <RideBoard
                setLoadStatus={setRbLoadStatus}
            />
        </>

    );
}

export default withAuthenticator(RideBoardScrn, rbAuthOptions);
