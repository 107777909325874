import * as React from 'react';
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {Modal} from "@mui/material";
import {makeStyles} from "@mui/styles";

function RbModal(props) {
    const useStyles = makeStyles({
        ModalCloseButton: {
            position: 'absolute',
            float: 'right',
            top: '-4px',
            right: '-4px',
            // left: '50%',
        }
    });
    const classes = useStyles();

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '600px',
        width: '90%',
        padding: 3,
        // overflow: 'scroll',
        overflow: 'auto',
        maxHeight: '80%',
        ...props.styleModal
    }

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper
                elevation={1}
                // sx={modalStyle}
                sx={modalStyle}
            >
                <IconButton
                    aria-label="Close"
                    className={classes.ModalCloseButton}
                    onClick={props.onClose}
                    // className={modalCloseButton}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {props.title}
                </Typography>
                {props.children}
            </Paper>
        </Modal>
    )
}

export default RbModal;
