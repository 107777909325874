/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      schoolID
      contactFacebook
      contactInstagram
      contactPhone
      rides {
        items {
          id
          userID
          date
          fromCity
          toCity
          seatsAvail
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      schoolID
      contactFacebook
      contactInstagram
      contactPhone
      rides {
        items {
          id
          userID
          date
          fromCity
          toCity
          seatsAvail
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      schoolID
      contactFacebook
      contactInstagram
      contactPhone
      rides {
        items {
          id
          userID
          date
          fromCity
          toCity
          seatsAvail
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRide = /* GraphQL */ `
  mutation CreateRide(
    $input: CreateRideInput!
    $condition: ModelRideConditionInput
  ) {
    createRide(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        firstName
        lastName
        schoolID
        contactFacebook
        contactInstagram
        contactPhone
        rides {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      fromCity
      fromLoc {
        lat
        lon
      }
      toCity
      toLoc {
        lat
        lon
      }
      seatsAvail
      notes
      createdAt
      updatedAt
    }
  }
`;
export const updateRide = /* GraphQL */ `
  mutation UpdateRide(
    $input: UpdateRideInput!
    $condition: ModelRideConditionInput
  ) {
    updateRide(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        firstName
        lastName
        schoolID
        contactFacebook
        contactInstagram
        contactPhone
        rides {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      fromCity
      fromLoc {
        lat
        lon
      }
      toCity
      toLoc {
        lat
        lon
      }
      seatsAvail
      notes
      createdAt
      updatedAt
    }
  }
`;
export const deleteRide = /* GraphQL */ `
  mutation DeleteRide(
    $input: DeleteRideInput!
    $condition: ModelRideConditionInput
  ) {
    deleteRide(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        firstName
        lastName
        schoolID
        contactFacebook
        contactInstagram
        contactPhone
        rides {
          nextToken
        }
        createdAt
        updatedAt
      }
      date
      fromCity
      fromLoc {
        lat
        lon
      }
      toCity
      toLoc {
        lat
        lon
      }
      seatsAvail
      notes
      createdAt
      updatedAt
    }
  }
`;
